import React, {Component} from 'react';
import './App.css';
import ReactTable from 'react-table'
import 'react-table/react-table.css'

const TITLE = "Import Logs";

const columns = [{
  Header: 'ListSlug',
  accessor: 'listName'
}, {
  Header: 'CreatedAt',
  accessor: 'createdAt',
}, {
  Header: 'UpdatedAt',
  accessor: 'updatedAt',
}, {
  Header: 'Available',
  accessor: 'available',
}, {
  Header: 'Eligible',
  accessor: 'eligible',
}, {
  Header: 'In Mb',
  accessor: 'in_mb',
}, {
  Header: 'In Group',
  accessor: 'in_group',
}, {
  Header: 'Status',
  accessor: 'status'
}
]

class App extends Component {
  state = {
    data: null,
    responseToPost: null
  };


  componentWillUnmount() {
    this.timer = null; // here...
  }

  async componentDidMount() {
    this.timer = setInterval(() => this.handleSubmit(), 2000);
    document.title = TITLE;
  }

  handleSubmit = async () => {

    const response = await fetch('/api/latest-import-logs', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });


    if (response.status === "404") {
      localStorage.setItem('loggedIn', false);
      localStorage.removeItem('slug');
      window.location.replace("/login");

    } else if (response.status === "503") {
    }
    else {
      try {
        const body = await response.json();
        this.setState({responseToPost: body});
      }
      catch (e) {
      }
    }
  };

  logOut = () => {
    localStorage.setItem("loggedIn", 'false');
    window.location.replace("/login");

  };


  render() {
    //console.log(localStorage.getItem("loggedIn"));
    if (localStorage.getItem("loggedIn") !== 'true') {
      window.location.replace("/login");
      return;
    }

    return (
      <div className="App">
        <header className="App-header">

          <h1 className="App-title">{TITLE}</h1>

        </header>
        <div style={{position: 'absolute', right: '1%', bottom: '1%'}}>





        </div>
        {this.state.responseToPost && <ReactTable
          data={this.state.responseToPost}
          columns={columns}
        />}

      </div>

    );
  }
}


export default App;