import React, {Component} from 'react';
import './App.css';
import './dropdown.css';
import SelectSearch from 'react-select-search'

const airtableLink = `https://airtable.com/${process.env.REACT_APP_AIRTABLE_BASE_SLUG}`
const TITLE = "NationBuilder MessageBird Importer";

class App extends Component {
  state = {
    data: null,
    options: [],
    selectedListIdAndSlug: {id: "", name: ""}
  };
  callBackendAPI = async () => {
    const response = await fetch('/api/express_backend?name=' + this.state.post, {
      headers: {
        'Content-type': 'application/json'
      },
      method: 'GET',

    })

    const body = await response.json();
    if (response.status !== 200) {
      throw Error(body.message)
    }
    return body;
  };

  getListID = async (name) => {
    const response = await fetch('/api/list_information?name=' + name, {
      headers: {
        'Content-type': 'application/json'
      },
      method: 'GET',

    });

    const body = await response.json();

    if (response.status !== 200) {
      throw Error(body.message)
    }
    return body;
  };

  handleSelection = e => {
    this.setState({selectedListIdAndSlug: {name: e.name, id: e.value}});
  };

  handleSubmit = async e => {

    this.setState({responseToPost: "Loading..."});
    e.preventDefault();
    const {name, id} = this.state.selectedListIdAndSlug
    fetch('/api/import_from_nb_list_id?name=' + name + '&id=' + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.props.history.push("/importlogs");
  };

  logOut = () => {
    localStorage.setItem("loggedIn", 'false');
    window.location.replace("/login");
  };

  async componentDidMount() {
    document.title = TITLE;
    const response = await fetch('api/get_mb_lists', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 404) {
      localStorage.setItem('loggedIn', false);
      window.location.replace("/login");

      return;
    }
    const body = await response.json();
    this.setState({options: body.lists.map(x => {return {name: x.slug, value: x.id}})});
  }


  render() {
    //console.log(localStorage.getItem("loggedIn"));
    if (localStorage.getItem("loggedIn") !== 'true') {
      window.location.replace("/login");
      return;
    }

    return (
      <div className="App">
        <header className="App-header">

          <h1 className="App-title">{TITLE}</h1>

        </header>
        <div style={{position: 'absolute', right: '1%', bottom: '1%'}}>
          {/* <button onClick={this.logOut} >Force Login</button> */}
        </div>
        <p>
          <strong>Enter slug of list to import into MessageBird:</strong>
        </p>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <SelectSearch onChange={this.handleSelection} options={this.state.options} value={this.state.selectedListIdAndSlug.id} name={this.state.selectedListIdAndSlug.id} placeholder="Choose the list" />
        </div>
        <button onClick={this.handleSubmit} >Import into MB</button>
        <div className="notes">
          <p>{this.state.responseToPost}</p>
          <p><b>Opt-outs</b>:</p>
          <p>In your SMS, tell users to reply STOP if they want to opt out. This will 
             automatically opt them out of future texts.</p>
          <p>&nbsp;</p>
          <p><b>User responses:</b></p>
          <p>Every response (other than STOP) received by users is recorded in Airtable.</p>
          <p><a href={airtableLink}>Link to the responses</a></p>
          <p>Be sure to check these!</p>
          <p>&nbsp;</p>
          <p><b>Use this tool responsibly:</b></p>
          <p>Please <b>delete your list</b> on NationBuilder after it's fulfilled its 
          purpose. This makes the system faster and more navigable for everyone. Thanks! :)</p>
        </div>
      </div>
    );
  }
}

export default App;
