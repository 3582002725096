import React, {Component} from 'react';

import './App.css';

const TITLE = "Redirecting..."

class Login extends Component {
  state = {
    data: null,
    selectedSlug: null
  };

  login = async e => {
    e.preventDefault();
    localStorage.setItem('slug', this.state.selectedSlug);

    const response = await fetch('/api/login?slug=' + this.state.selectedSlug, {
      method: 'GET',
    });


    if (response.status === 200) {
      const json = await response.json();
      localStorage.setItem('loggedIn', true);
      window.location.replace(json.url);
    }
    else {
      const body = await response.text();
      localStorage.setItem('loggedIn', false);
      localStorage.removeItem('slug');
      this.setState({responseToPost: body});

    }


  };

  async componentDidMount() {
    document.title = TITLE;
    const response = await fetch('/api/login?slug=', {
      method: 'GET',
    });


    if (response.status === 200) {
      const json = await response.json();
      window.location.replace(json.url);
    }
    else {
      const body = await response.text();
      localStorage.setItem('loggedIn', false);
      this.setState({responseToPost: body});

    }
  }


  render() {

    return (

      < div >



        <div className="App">
          <header className="App-header">
            <h1 className="App-title">{TITLE}</h1>
          </header>
          {/* <p>Please, you need to be authenticated to to view this content</p>
          <form onSubmit={this.login}>
            <p>
              <strong>Enter the slug of your nation login:</strong>
            </p>
            <input
              type="text"
              value={this.state.post}
              onChange={e => this.setState({selectedSlug: e.target.value})}
            />
            <button type="submit">Login</button>
          </form>
          <p>{this.state.responseToPost}</p> */}

        </div>
      </div >


    );
  }
}


export default Login;