import React, {Component} from 'react';
import './App.css';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom'
import Admin from './Admin'
import ImportLog from './ImportLog'
import OptOut from './OptOut'
import Login from './Login'

class LoginSuccess extends Component {


  async componentDidMount() {
    //console.log('mounting!');
    const loggedIn = await this.isLoggedIn()
    console.log('mounting!', loggedIn);

    if (loggedIn) {
      //console.log("Logging in on the front end")
      localStorage.setItem('loggedIn', true);
      window.location.replace('/admin');

    }
    else {
      localStorage.setItem('loggedIn', false);
      window.location.replace('/login');
    }
  }


  isLoggedIn = async () => {
    const response = await fetch('api/isLoggedIn', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const body = await response.json();
    console.log(body)
    return body.isLoggedIn;

  }
  render() {return (<div></div>)}
}


class App extends Component {
  state = {
    data: null
  };

  render() {
    return (
      <BrowserRouter>
        <div>
          <Switch>
            <Route path="/admin" component={Admin} />
            <Route path="/importLogs" component={ImportLog} />
            <Route path="/txtoptout" component={OptOut} />
            <Route path="/login" component={Login} />
            <Route path="/loginSuccess" component={LoginSuccess} />
            <Redirect exact from="" to="/txtoptout" />
          </Switch>
       </div>
      </BrowserRouter>

    );
  }
}


export default App;
