import React, {Component} from 'react'
import logo from './momentum-logo.png'
import './App.css'
import './OptOut.css'

class OptOut extends Component {

  render() {
    return (
      <div>
        <header className="header">
          <img className="logo" src={logo} alt="Logo" />
        </header>
        <div className="message">
          <p>To opt out of text messages from Momentum, reply STOP to any message you've received.</p>
          <p><a href="https://peoplesmomentum.com/privacy-policy/">Privacy policy</a></p>
        </div>
      </div >

    );
  }
}

export default OptOut;
